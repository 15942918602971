<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard 
    Author:  
    Author URL:  
========================================================================================== -->
<template>
  <div>
    <!-- ADD DEPARTMENT FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Currency">
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Currency Name *</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required|alpha_spaces'"
                        name="Currency Name"
                        v-model="form.currency_name"
                        class="w-full"
                        rules="alpha"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Currency Name')"
                        >{{ errors.first("Currency Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Currency Code *</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required|alpha'"
                        name="Currency Code"
                        @keyup="form.currency_code=form.currency_code.toUpperCase()"
                        v-model="form.currency_code"
                        class="w-full"
                        rules="alpha"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Currency Code')"
                        >{{ errors.first("Currency Code") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h6 class="mb-0">Status</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <!-- <vs-switch vs-icon-off="2" vs-icon-on="1" v-model="form.currency_active" /> -->
                    <b-form-checkbox
                      value="1"
                      unchecked-value="2"
                      v-model="form.currency_active"
                      name="check-button"
                      switch
                    ></b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
              <!-- <div class="vx-col">
                <div class="excel-btn-1">
                  <vx-tooltip text="Template Excel" position="left">
                    <vs-button
                      @click.native="onClickSampleDownload(excelurl+`/v1/currency/excel/sample`)"
                      type="border"
                      class="py-2"
                    >
                      <img
                        class="d-inline-flex"
                        src="@/assets/images/icons/excel.svg"
                        height="20"
                        width="20"
                      />
                    </vs-button> 
                  </vx-tooltip>
                </div>

                <div class="excel-btn-2">
                  <vx-tooltip text="Import Excel" position="left">
                    <label for="import-file">
                      <input
                        ref="uploadExcelFile"
                        @change="uploadExcel($event)"
                        type="file"
                        name="import-file"
                        id="import-file"
                        class="d-none"
                      /> 
                      <div class="vs-component vs-button px-4 vs-button-primary vs-button-filled">
                        <span class="d-inline-flex pr-5 text-white">Import</span>
                        <img
                          class="d-inline-flex"
                          src="@/assets/images/icons/import-excel.svg"
                          height="20"
                          width="20"
                        />
                      </div>
                    </label>
                  </vx-tooltip>
                </div>
              </div> -->
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BASEURL } from "@/config/index.js";
import axios from "axios";
import { TokenService } from "@/services/storage.service.js";
import CurrencyService from "@/services/currencyService";
const VxTour = () => import("@/components/VxTour.vue");

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      excelurl: BASEURL,
      submitStatus: true,
      gridApi: null,
      form: {
        currency_name: "",
        currency_code: "",
        currency_active: 1,
      },
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },
    uploadExcel: function (event) {
      let that = this;
      that.$vs.loading();
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios
        .post(that.excelurl + `/v1/currency/import/excel`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const { data } = response;
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          if (
            data &&
            data["error"] &&
            data.error["message"] &&
            data.error.message.length > 0
          ) {
            that.$vs.notify({
              // title: "Duplicate entries have not been imported.",
              title: "Error!",
              text: data.error.message || "Error",
              iconPack: "feather",
              icon: "check_box",
              // time:8000,
              time: 16000,
              color: "warning",
            });
          } else {
            that.$vs.notify({
              title: "Updated!",
              text: data.data.message || "Updated",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
          }
          that.$vs.loading.close();
          // that.$vs.notify({
          //   title: "Updated!",
          //   text: data.message,
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "success"
          // });
          eventBus.$emit("refreshTable", data);
        })
        .catch((error) => {
          that.$vs.loading.close();
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          that.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.submitStatus = false;
          let payload = {
            ...this.form,
          };
          CurrencyService.addCurrency(payload)
            .then((response) => {
              this.submitStatus = false;
              const { data } = response;
              this.$vs.loading.close();
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                eventBus.$emit("refreshTable", data);

                this.clearForm();
              } else {
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.submitStatus = true;
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },

    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.description = "";
      this.value = [];
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    // this.gridApi = this.gridOptions.api;
  },
};
</script>
